<template>
  <div class="content content_in">
    <div class="warn-block mb-3" v-if="showWarnBlock">
      {{ $t("domain.google_warn") }}
      <svg v-svg symbol="close-red" @click="showWarnBlock = false" size="0 0 20 20" class="close" role="info" />
    </div>
    <div class="title-row d-flex row justify-content-between">
      <div class="col-12 d-flex w-100 col-md-auto">
        <h1 class="w-100">{{ $t("domain.title") }}</h1>
        <button @click.prevent="$modal.show('buyDomain')" class="btn">
          {{ $t("domain.buy_domain") }}
        </button>
      </div>
    </div>
    <div class="row mt-3 mb-4">
      <div class="col-12">
        <ul class="tabs justify-content-start mb-0">
          <li v-for="(tab, index) in tabs" :key="index" class="col-sm-12 col-lg-6">
            <button @click.prevent="currentTab = tab" :class="{ active: currentTab === tab }">
              <svg v-svg :symbol="tab === 'archive' ? 'delete' : 'active'" size="0 0 15 15" role="info" />
              {{ $t(`domain.tabs.${tab}`) }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="c-table">
      <div class="inner">
        <vue-good-table styleClass="table  _no-wrap" :columns="columns" :rows="rows">
          <template slot="table-column" slot-scope="props">
            <span @click="toSort(props.column.field)" class="cursor-pointer">
              {{ formatLabel(props.column.label) }}
            </span>
            {{ props.column.field !== "control" ? ":" : "" }}
          </template>

          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'status'">
              <span class="domain-field">
                <small v-if="props.row.is_banned_by_gsb" class="locked-by-google">
                  <svg v-svg symbol="info" size="0 0 15 15" role="info" />
                  Google Safe Browsing
                </small>
                <span class="active" v-else>
                  <svg v-svg color="red" symbol="active" size="0 0 15 16" />
                  Active</span
                >
              </span>
            </template>

            <template v-else-if="props.column.field === 'type'">
              {{ props.row.category }}
            </template>

            <template v-else-if="props.column.field === 'control'">
              <ul class="controls">
                <li>
                  <button @click="trashDomain(props.row.id)" class="controls__btn" :class="{ trash: !isActive }" :title="$t('select.deselectLabel')">
                    <svg v-if="!isActive" width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.48457 0.307119L0.199266 5.45137C-0.0664225 5.7686 -0.0664225 6.2314 0.199266 6.54863L4.48457 11.6929C4.79311 12.053 5.33309 12.1045 5.69306 11.8044C6.05302 11.4958 6.10445 10.9557 5.80448 10.5956L1.97344 6L5.80448 1.40437C6.10445 1.04428 6.05302 0.504206 5.69305 0.195551C5.33309 -0.104531 4.79311 -0.0529787 4.48457 0.307119Z" />
                    </svg>

                    <svg v-svg symbol="delete" size="0 0 15 15" role="info" />
                  </button>
                </li>
              </ul>
            </template>
          </template>
          <div class="d-flex justify-content-center" slot="emptystate">
            <template v-if="!loadDataTable">
              {{ $t("any_results") }}
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <img :src="require('@/assets/images/rolling.svg')" alt="loader" class="loader" />
              </div>
            </template>
          </div>
        </vue-good-table>
      </div>
    </div>
    <div class="c-pagination">
      <ul class="pagination" v-if="rows && rows.length && rows.length > 0">
        <li class="page-item">
          <button @click.prevent="page--" :class="{ disabled: page <= 1 }" :disabled="page <= 1" class="page-link icon-prev prev" data-ci-pagination-page="2" rel="prev" :title="$t('statistic.prev')">
            <svg v-svg symbol="prev" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">
          <button @click.prevent="page++" :class="{ disabled: page * limit >= total }" :disabled="page * limit >= total" class="page-link icon-next next" data-ci-pagination-page="2" rel="next" :title="$t('statistic.next')">
            <svg v-svg symbol="next" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">{{ page === 1 ? page : limit * (page - 1) + 1 }} - {{ limit * (page - 1) + rows.length }} {{ $t("from") }} {{ total }}</li>
        <li>
          <custom-multiselect class="ml-3" v-model="limit" :list="optionsPagination" :allowEmpty="false">
            <template v-slot:block="props">
              <span>
                {{ props.value }}
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                {{ props.value }}
              </span>
            </template>
          </custom-multiselect>
        </li>
      </ul>
    </div>
    <modal @closed="$modal.hide('buyDomain')" :width="570" name="buyDomain" height="auto" :scrollable="false" classes="creative-modal" :shiftY="0">
      <BuyDomain @closeModal="$modal.hide('buyDomain')" @refresh="refreshDomain" @showError="showError" />
    </modal>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import "@/assets/css/_pagination.scss";
import serviceDomain from "@/api/serviceDomain";
import BuyDomain from "@/components/BuyDomain.vue";

export default {
  name: "Domain",
  components: { BuyDomain },
  data() {
    return {
      bannedDomainIds: null,
      showWarnBlock: false,
      currentTab: "active",
      tabs: ["active", "archive"],
      rows: [],
      columns: [
        {
          label: "platforms.table.id",
          field: "id",
          sortable: false,
        },
        {
          label: "platforms.domain",
          field: "domain",
          sortable: false,
        },
        {
          label: "platforms.table.type",
          field: "type",
          sortable: false,
        },
        {
          label: "platforms.table.status",
          field: "status",
          sortable: false,
        },
        {
          label: "domain.table.buy_date",
          field: "buy_date",
          thClass: `sortable spec-sort`,
          sortable: true,
        },
        {
          label: "domain.table.expires_at",
          field: "expires_at",
          sortable: false,
        },
        {
          label: "",
          field: "control",
          width: "35%",
          sortable: false,
        },
      ],

      page: 1,
      offset: null,
      limit: 10,
      total: null,
      order: null,
      sort: null,
      loadDataTable: false,
      optionsPagination: [10, 25, 50, 100],
    };
  },
  watch: {
    currentTab() {
      this.refreshDomain();
      this.page = 1;
      this.offset = null;
      this.$router
        .push({
          path: "/domain",
        })
        .catch(() => {});
    },
    $route: function () {
      const params = this.$route.query;
      this.offset = params.offset || 0;
      if (this.offset === 0) {
        this.page = 1;
      } else {
        this.page = this.offset / this.limit + 1;
      }
    },
    sort: function () {
      this.checkTableSpan();
    },
    order: function () {
      this.refreshDomain();
      this.checkTableSpan();
    },
    trash: function () {
      this.refreshDomain();
    },
    limit: function () {
      this.page = 1;
      localStorage.setItem("paginationLimit", this.limit);
      this.refreshDomain();
    },
    offset: function () {
      this.refreshDomain();
    },
    page: function () {
      if (this.page === 1 || this.page < 0) {
        this.offset = null;
        this.$router
          .push({
            path: "/domain",
          })
          .catch(() => {});
      } else {
        this.offset = this.limit * (this.page - 1);
        this.$router
          .push({
            path: "/domain",
            query: { offset: this.offset },
          })
          .catch(() => {});
      }
    },
    bannedDomainIds(val) {
      if (val === "") {
        this.showWarnBlock = false;
        localStorage.setItem("blocked-by-gsp", "");
      }
    },
    showWarnBlock(val) {
      if (val === false) localStorage.setItem("blocked-by-gsp", this.bannedDomainIds + "false");
    },
  },
  mounted() {
    if (localStorage.getItem("paginationLimit")) {
      this.limit = Number(localStorage.getItem("paginationLimit"));
    }
    this.refreshDomain();
  },
  computed: {
    isActive() {
      return this.currentTab === "active";
    },
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    toSort(val) {
      if (val === "buy_date") {
        this.order = this.sort === val ? (this.order === "asc" ? "desc" : "asc") : "desc";
        this.sort = val;
      }
    },
    showError(message) {
      this.$alert({
        title: message,
      });
    },
    refreshDomain() {
      this.loadDataTable = true;
      let params = this.$route.query;
      let payload = {
        trash: +!this.isActive,
        offset: params.offset ? params.offset : this.offset,
        limit: this.limit,
        order: this.order,
        sort: this.sort,
      };
      serviceDomain
        .getDomain(payload)
        .then((resp) => {
          if (resp && resp.status === 200) {
            this.rows = resp.data.domains;
            this.total = resp.data.total;

            if (!payload.trash) {
              // Get the list of banned domain IDs as a comma-separated string
              this.bannedDomainIds = this.rows
                .filter((row) => row.is_banned_by_gsb)
                .map((row) => row.id)
                .toString();

              const gspBlocked = localStorage.getItem("blocked-by-gsp") || "";
              const isBlockedInStorage = gspBlocked.includes(this.bannedDomainIds);
              const isBlockedTrue = gspBlocked.includes("true");
              const isBlockedFalse = gspBlocked.includes("false");

              const blockedIds = gspBlocked.match(/\d+/g) ? gspBlocked.match(/\d+/g).map(Number).toString() : "";

              // Condition to show warning block
              if ((isBlockedInStorage && isBlockedTrue) || (this.bannedDomainIds && (!gspBlocked || isBlockedTrue)) || (this.bannedDomainIds !== blockedIds && isBlockedFalse)) {
                this.showWarnBlock = true;
              } else {
                this.showWarnBlock = false;
              }

              localStorage.setItem("blocked-by-gsp", `${this.bannedDomainIds}${this.showWarnBlock}`);
            }
          }
        })
        .finally(() => {
          this.loadDataTable = false;
        });
    },
    trashDomain(id) {
      const payload = {
        trash: this.isActive,
      };
      serviceDomain.trashDomain(id, payload).then(() => {
        this.refreshDomain();
      });
    },
    checkTableSpan() {
      const thElements = document.querySelectorAll("th.sortable"); // all sortable table columns
      thElements.forEach((thElement) => {
        const spanElement = thElement.querySelector("span"); // span of an current iteration th
        if (spanElement && spanElement.innerText.trim() === this.$t(this.columns.find((el) => el.field === this.sort).label).trim()) {
          // check if an span text === current sort value text
          thElement.classList.remove(
            // remove oposite class
            this.order && this.order === "desc" ? "sorting-asc" : "sorting-desc"
          );
          thElement.classList.add(
            // add class to the current sort th
            this.order && this.order === "desc" ? "sorting-desc" : "sorting-asc"
          );
          return false;
        } else {
          // if no remove all classes
          thElement.classList.remove("sorting-desc", "sorting-asc");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.warn-block {
  position: relative;
  background: #fff5f5;
  padding: 20px;
  border-radius: 10px;
  color: #b84e4e;
  svg.close {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

span.domain-field {
  width: 33%;
  display: inline-block;
  span.active {
    color: #37807d;
    svg {
      fill: #37807d;
    }
  }
}
small.locked-by-google,
span.active {
  color: #c62424;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  svg {
    margin-bottom: 2px;
    fill: #c62424;
  }
}

.content.content_in {
  padding-bottom: 150px;

  &::v-deep {
    table thead th.sortable {
      cursor: pointer;
    }
  }

  .controls__btn {
    display: flex;
    align-items: center;
    gap: 0 8px;
    width: 36px;

    &.trash {
      svg {
        fill: #38807d;
      }
    }

    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
}
</style>
